import React from "react";

import styles from "./styles";

const Header = () => (
  <div className={styles.root}>
    <div className={styles.intro}>
      <a href="#main">
        <h1 className={styles.name}>Silvia Rebelo</h1>
        <div className={styles.title}>Senior Web Developer</div>
      </a>
    </div>
    <ul className={styles.navigation}>
      <li>
        <a
          href="https://github.com/teknotica"
          target="_blank"
          rel="noreferrer noopener"
        >
          Github
        </a>
      </li>
      <li>
        <a
          href="https://www.linkedin.com/in/srebelo"
          target="_blank"
          rel="noreferrer noopener"
        >
          LinkedIn
        </a>
      </li>
    </ul>
  </div>
);

export default Header;
